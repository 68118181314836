import { Helmet } from 'react-helmet-async';
import React from 'react';

import EasyNotes from 'assets/fonts/EasyNotes.woff2';
import GraphikSemibold from 'assets/fonts/Graphik-Semibold.woff2';
import GraphikRegular from 'assets/fonts/Graphik-Regular.woff2';
import GraphikMedium from 'assets/fonts/Graphik-Medium.woff2';
import GraphikBold from 'assets/fonts/Graphik-Bold.woff2';

import faviconIco from 'assets/favicon/favicon.ico';
import faviconSvg from 'assets/favicon/favicon.svg';
import appleTouchIcon from 'assets/favicon/apple-touch-icon.png';
import favicon32x32 from 'assets/favicon/favicon-32x32.png';
import favicon192x192 from 'assets/favicon/favicon-192x192.png';
import milaIcon from 'assets/favicon/mila-icon.png';
import milacares from 'assets/images/milacares.jpg';

/**
 * @param pathname
 * @returns {JSX.Element}
 * @constructor
 */
const HelmetWrapper = ({ pathname }) => (
  <Helmet>
    <meta charSet='utf-8' />

    {/* <!-- font's  --> */}
    <link
      rel='preload'
      href={EasyNotes}
      as='font'
      type='font/woff2'
      crossOrigin
    />
    <link
      rel='preload'
      href={GraphikSemibold}
      as='font'
      type='font/woff2'
      crossOrigin
    />
    <link
      rel='preload'
      href={GraphikRegular}
      as='font'
      type='font/woff2'
      crossOrigin
    />
    <link
      rel='preload'
      href={GraphikMedium}
      as='font'
      type='font/woff2'
      crossOrigin
    />
    <link
      rel='preload'
      href={GraphikBold}
      as='font'
      type='font/woff2'
      crossOrigin
    />

    {/* <!-- favicon's  --> */}
    <link rel='icon' href={faviconIco} sizes='any' />
    <link rel='icon' href={faviconSvg} type='image/svg+xml' />
    <link rel='apple-touch-icon' href={appleTouchIcon} />
    <link rel='icon' href={favicon32x32} sizes='32x32' />
    <link rel='icon' href={favicon192x192} sizes='192x192' />
    <link rel='apple-touch-icon' href={milaIcon} />
    <meta name='msapplication-TileImage' content={milaIcon} />
    <link rel='canonical' href={`${BASE_URL}${pathname}`} />

    <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
    <meta name='viewport' content='width=device-width, initial-scale=1' />

    {/* <!-- Primary Meta Tags --> */}
    <meta name='title' content='Say hello 👋 to Mila, the refreshingly thoughtful air purifier' />
    <meta
      name='description'
      content='Air breathers, rejoice. Meet Mila, the first air purifier designed with real, air-breathing humans in mind.'
    />

    {/* <!-- Open Graph / Facebook --> */}
    <meta property='og:title' content='Say hello 👋 to Mila, the refreshingly thoughtful air purifier' />
    <meta
      property='og:description'
      content='Air breathers, rejoice. Meet Mila, the first air purifier designed with real, air-breathing humans in mind.'
    />
    <meta property='og:url' content='https://www.milacares.com/' />
    <meta property='og:type' content='articles' />
    <meta property='og:site_name' content='Mila' />
    <meta name='image' property='og:image' content={`${BASE_URL}${milacares}`} />
    <meta name='author' content='The Mila Team' />

    {/* <!-- Twitter --> */}
    <meta property='twitter:card' content='summary_large_image' />
    <meta property='twitter:url' content='https://www.milacares.com/' />
    <meta property='twitter:title' content='Say hello 👋 to Mila, the refreshingly thoughtful air purifier' />
    <meta
      property='twitter:description'
      content='Air breathers, rejoice. Meet Mila, the first air purifier designed with real, air-breathing humans in mind.'
    />
    <meta property='twitter:image' content={`${BASE_URL}${milacares}`} />
  </Helmet>
);

export default HelmetWrapper;
